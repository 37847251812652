<template>
  <div v-if="!showConfirmation">
    <h2 class="title title--form text-center">Добро пожаловать!</h2>
    <form @submit.prevent="submit">

      <p class="form-error" v-if="errors.message">{{ errors.message }}</p>

      <div class="form-field">
        <input
            class="input"
            v-model="authData.email"
            placeholder="E-mail"
            type="email"
            required
            @focus="focusHandler"
        />
        <p class="form-error" v-for="message in errors.errors.email">{{ message }}</p>
      </div>
      <div class="form-field">
        <input
            class="input input--password"
            v-model="authData.password"
            :type="passVisible ? 'text' : 'password'"
            placeholder="Пароль"
            required
            @focus="focusHandler"
        />
        <button
            type="button"
            class="password-visibility"
            :class="{'visible' : passVisible}"
            @click="passVisible = !passVisible"
        ></button>
        <p class="form-error" v-for="message in errors.errors.password">{{ message }}</p>
      </div>

      <div class="form-field">
        <nuxt-link class="form-link link-simple" to="/recovery">Восстановить пароль</nuxt-link>
      </div>

      <div class="form-controls row gx-0 justify-content-center">
        <div class="col-auto">
          <nuxt-link class="button button--transparent" to="/registration">Регистрация</nuxt-link>
        </div>
        <div class="col-auto position-relative" :class="{'--loading': loading}">
          <button
              class="button"
              :disabled="!authData.email || !authData.password"
              type="submit"
          >Вход</button>
          <Preloader v-if="loading" class="for-button" invert />
        </div>
      </div>
    </form>
  </div>
  <ConfirmEmail v-else :email="authData.email" :is-new="false" />
</template>

<script setup lang="ts">

import {ApiRequest} from "~/utils/api-request";
import { ref } from 'vue';
import ConfirmEmail from "~/components/ConfirmEmail.vue";
import cookie from "~/utils/cookie";
import Preloader from "~/components/page/Preloader.vue";

definePageMeta({
  layout: 'auth'
})

useSeoMeta({
  title: 'Авторизация',
  description: 'Авторизация'
})

let authData = ref({
  email: null!,
  password: null!
});

let errors = ref({
  message: null!,
  errors: {

  }
});

let passVisible = ref(false);
let showConfirmation = ref(false);
const router = useRouter();
const loading = ref(false);

const submit = async () => {
  loading.value = true;
  passVisible.value = false;

  let apiRequest = new ApiRequest('post', 'auth/login/by-email', null, authData.value);

  apiRequest.onSuccess((data) => {
    cookie.authToken.setValue(data.accessToken)

    // console.log(data);
    loading.value = true;
    window.location.assign("/cabinet");
    // router.push({ path: "/cabinet" });
  });

  apiRequest.onValidationError((error) => {
    errors.value = error;
    loading.value = false;
  });

  apiRequest.onError((error) => {
    errors.value = error;
    loading.value = false;

    // if email is not confirmed
    if (error.errors.hasOwnProperty('email_verified_at')){
      showConfirmation.value = true;
    }
  });

  apiRequest.onServerError(() => {
    errors.value.message = 'ошибка сервера 2 (500>)';
  });

  return await apiRequest.request();
};

function focusHandler(){
  Object.keys(errors.value.errors).forEach((key) => {
    errors.value.errors[key] = [];
  });
}
</script>
